import "../../assets/Styles/NewNavbar.css";
import NavLogo from "../../assets/Images/NavLogo.png";
import {
  MdOutlineKeyboardArrowDown,
  MdCreate,
  MdClose,
  MdMenu,
} from "react-icons/md";
import { FaFire } from "react-icons/fa";
import { FaHandFist } from "react-icons/fa6";
import { AiOutlineAntDesign } from "react-icons/ai";
import { SiCssdesignawards } from "react-icons/si";



import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isTokenValid } from "../../AuthCheck";
import { get_UserInfo } from "../../api/services/userService";
import { useDispatch, useSelector } from "react-redux";
import { PROFILEINFO } from "../../Redux/Actions";
import ProfileSection from "./navbarComponent/ProfileSection";

const NavBar = () => {
  const menuRef = React.useRef(null);
  const [hoverResumeServices, setHoverResumeServices] = useState(false);
  const [hoverCoverLetterServices, setHoverCoverLetterServices] =
    useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const tokenValid = isTokenValid();
  const dispatch = useDispatch();
  const ProfileInfo = useSelector((data) => data?.userReducer?.userData);
  const isValidToken = isTokenValid();
  const location = useLocation();

  const getUserData = async () => {
    try {
      const response = await get_UserInfo();
      const data = response?.data?.data;
      dispatch(PROFILEINFO(data));
    } catch (err) {
      if (err?.status == 401) {
        localStorage.removeItem("LoginToken");
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (isValidToken) {
      getUserData();
    }
  }, [isValidToken]);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (
      currentScrollY > 100 &&
      currentScrollY < 800 &&
      currentScrollY > lastScrollY
    ) {
      setIsNavbarVisible(false);
    } else {
      setIsNavbarVisible(true);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      closeMenu();
    }
  };

  // Close menu with Escape key
  const handleKeyUp = (event) => {
    if (event.key === "Escape") {
      closeMenu();
    }
  };

  // Event listeners for outside click and keyup
  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <nav className={`navbar ${isNavbarVisible ? "visible" : "hidden"}`}>
        <div className="navbar-left">
          <Link to="/">
            <img src={NavLogo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="navbar-center">
          <ul className="navbar-links">
            <li
              className="dropdown"
              onMouseEnter={() => setHoverResumeServices(true)}
              onMouseLeave={() => setHoverResumeServices(false)}
            >
              <Link
                className={`${hoverResumeServices ? "nav-link-btn-active" : "nav-link-btn"
                  } resume-drop d-flex align-items-center`}
                to="/templatecard"
              >
                Resume Templates
                <MdOutlineKeyboardArrowDown size={20} />
              </Link>
              <div
                className={`dropdown-content mega-menu ${hoverResumeServices ? "show" : ""
                  }`}
                onMouseLeave={() => setHoverResumeServices(false)}
              >
                <div className="mega-menu-container">
                  <div className="resume-templates">
                    <h5>Resume Templates</h5>
                    <ul>
                      <li>
                        <Link
                          to="/templatecard"
                          state={{ value: "simple" }}
                          replace={true}
                          onClick={() => setHoverResumeServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <FaFire size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Simple
                        </Link>
                        <p>
                          Clean, timeless templates with a classic balanced
                          structure. A perfect basic canvas
                        </p>
                      </li>
                      <li>
                        <Link
                          to="/templatecard"
                          state={{ value: "professional" }}
                          replace={true}
                          onClick={() => setHoverResumeServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          Professional
                        </Link>
                        <p>
                          Job-winning templates to showcase professionalism,
                          dependability, and expertise
                        </p>
                      </li>

                      <li>
                        <Link
                          to="/templatecard"
                          state={{ value: "modern" }}
                          replace={true}
                          onClick={() => setHoverResumeServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <SiCssdesignawards
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          Modern
                        </Link>
                        <p>
                          A current and stylish feel for forward-thinking
                          candidates in innovative fields
                        </p>
                      </li>

                      <li>
                        <Link
                          to="/templatecard"
                          state={{ value: "creative" }}
                          replace={true}
                          onClick={() => setHoverResumeServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <MdCreate size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Creative
                        </Link>
                        <p>
                          A bold, original feel perfect for artistic fields and
                          contemporary companies
                        </p>
                      </li>

                      {/* <li>
                        <Link
                          to="/templatecard"
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <AiOutlineAntDesign
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          ATS
                        </Link>
                        <p>
                          Optimise your resume and impress employers with these
                          ATS-friendly designs.
                        </p>
                      </li> */}

                    </ul>

                    {/* <div
                      style={{
                        width: "300px",
                        padding: "15px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                        fontFamily: "Arial, sans-serif",
                      }}
                    >
                      <img
                        src="image.png"
                        alt="Resume Example"
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          marginBottom: "10px",
                        }}
                      />
                      <h3
                        style={{
                          margin: "0",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        500+ Free Resume Examples by industry
                      </h3>
                      <p
                        style={{
                          margin: "10px 0",
                          fontSize: "14px",
                          color: "#555",
                        }}
                      >
                        Use the expert guides and our resume builder to create a beautiful
                        resume in minutes.
                      </p>
                      <a
                        href="#"
                        style={{
                          color: "#007bff",
                          textDecoration: "none",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Get started now →
                      </Link>
                    </div> */}
                  </div>

                </div>
              </div>
            </li>

            <li
              className="dropdown"
              onMouseEnter={() => setHoverCoverLetterServices(true)}
              onMouseLeave={() => setHoverCoverLetterServices(false)}
            >
              <Link
                to="/CvtemplateCard"
                className={`${hoverCoverLetterServices
                  ? "nav-link-btn-active"
                  : "nav-link-btn"
                  } d-flex align-items-center`}
              >
                Cover Letter
                <MdOutlineKeyboardArrowDown size={20} />
              </Link>
              <div
                className={`dropdown-content mega-menu ${hoverCoverLetterServices ? "show" : ""
                  }`}
                onMouseLeave={() => setHoverCoverLetterServices(false)}
              >
                <div className="mega-menu-container">
                  <div className="resume-templates">
                    <h5>Cover Letter Templates</h5>
                    <ul>
                      <li>
                        <Link
                          to="/cvtemplatecard"
                          state={{ value: "simple" }}
                          replace={true}
                          onClick={() => setHoverCoverLetterServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <FaFire size={18} color="rgba(164, 35, 192, 1" />
                          </span>
                          Simple
                        </Link>
                        <p>
                          {" "}
                          Clean, straightforward templates aiming to keep the
                          focus on your letter’s writing and content{" "}
                        </p>
                      </li>

                      {/* <li>
                        <Link
                          to="/mycv"
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          My Cv
                        </Link>
                        <p>
                          {" "}
                          A current and stylish feel for forward-thinking
                          candidates in innovative fields{" "}
                        </p>
                      </li> */}

                      <li>
                        <Link
                          to="/cvtemplatecard"
                          state={{ value: "professional" }}
                          replace={true}
                          onClick={() => setHoverCoverLetterServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <FaHandFist
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          Professional
                        </Link>
                        <p>
                          {" "}
                          Polished designs to help you highlight your knowledge
                          and expertise in formal fields{" "}
                        </p>
                      </li>

                      <li>
                        <Link
                          to="/cvtemplatecard"
                          state={{ value: "modern" }}
                          replace={true}
                          onClick={() => setHoverCoverLetterServices(false)}
                          className="d-flex align-items-center gap-1"
                        >
                          <span>
                            <SiCssdesignawards
                              size={18}
                              color="rgba(164, 35, 192, 1"
                            />
                          </span>
                          Modern
                        </Link>
                        <p>
                          Sleek and attractive designs for forward-thinking
                          candidates in innovative fields
                        </p>
                      </li>

                      <li>
                        <div>
                          <Link
                            to="/cvtemplatecard"
                            state={{ value: "creative" }}
                            replace={true}
                            onClick={() => setHoverCoverLetterServices(false)}
                            className="d-flex align-items-center gap-1"
                          >
                            <span>
                              <MdCreate
                                size={18}
                                color="rgba(164, 35, 192, 1"
                              />
                            </span>
                            Creative
                          </Link>
                        </div>
                        <p>
                          {" "}
                          Stylish templates defined by a unique approach to show
                          off your originality and inspiration{" "}
                        </p>
                      </li>
                    </ul>
                    {/* <div className="row">
                     <div className="col-1">
                      <Link to="#" className="view-all">View all</Link>
                     </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/blog" className="nav-link-btn">
                Blog
              </Link>
            </li>
            {/* <li>
              <Link to="/pricingplan" className="nav-link-btn">
                Pricing
              </Link>
            </li> */}

            <div className="navbar-right">
              {location.pathname !== "/login" && !tokenValid && (
                <Link className="my-account" to="/login">
                  Login
                </Link>
              )}

              {tokenValid && (
                <Link
                  className="btn-build-resume text-white"
                  to="/member/default"
                >
                  Dashboard
                </Link>
              )}
            </div>
            <li>
              {/*profile icons */}
              {tokenValid && <ProfileSection ProfileInfo={ProfileInfo} />}
            </li>
          </ul>

          {/* Hamburger Menu  Phone screen*/}
          <div className="hamburger" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <MdClose size={28} /> : <MdMenu size={28} />}
          </div>

          {/* Mobile Menu */}
          <ul
            className={`menu ${isMobileMenuOpen ? "active" : ""}`}
            ref={menuRef}
          >
            <li onClick={toggleMobileMenu}>
              <Link to="/">Home</Link>
            </li>
            {/* <li onClick={toggleMobileMenu}>
              <Link to="/MyResume">My Resume</Link>
            </li> */}
            <li onClick={toggleMobileMenu}>
              <Link to="/blog">Blog</Link>
            </li>
            {/* <li onClick={toggleMobileMenu}>
              <Link to="/pricingplan">Pricing</Link>
            </li> */}
            <li onClick={toggleMobileMenu}>
              <Link to="/login">Login</Link>
            </li>
            {tokenValid && (
              <li onClick={toggleMobileMenu}>
                <Link to="/member/default">Dashboard</Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};
export default NavBar;
