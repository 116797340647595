import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";


const ProfileSection = ({ ProfileInfo }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem('LoginToken');
    navigate('/login')
  }

  const f_namel = ProfileInfo.f_name?.charAt(0).toUpperCase();
  const l_namel = ProfileInfo.l_name?.charAt(0).toUpperCase();

  return (
    <>


      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}>
        {ProfileInfo?.profile ?
          <Avatar
            onClick={handleClick}
            src={`https://resumecv.idea2reality.tech/storage/userProfile/${ProfileInfo?.profile}`}
            sx={{ width: 40, height: 40 }} // Small profile image size
          /> :
          <Tooltip title="">
            <IconButton
              onClick={handleClick}
              size="large"
              sx={{ p: 0 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}>
              <Avatar>{f_namel + l_namel}</Avatar>
            </IconButton>
          </Tooltip>}

      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 28,
                height: 28,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>

        <MenuItem disabled>
          <p className="pb-1 text-capitalize m-0">{ProfileInfo?.f_name} {ProfileInfo?.l_name}</p>
        </MenuItem>

        <MenuItem onClick={() => navigate('/member/profile')}>
          <Avatar src={`https://resumecv.idea2reality.tech/storage/userProfile/${ProfileInfo?.profile}`} height="40px" width='40px' className="rounded-circle" /> Profile
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
            <Avatar /> My account
         </MenuItem> */}
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileSection;
