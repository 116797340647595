import React from 'react';
import { data } from "../Data/data";
import "../assets/Styles/Template4.css";

export default function Template4(props) {

    const personalInfo = props.personalInfo ? props.personalInfo : data.personal_info;

    const skills = props.skills ? props.skills : data.key_skills;

    const educationInfo = props.educationInfo ? props.educationInfo : data.education_details;

    const experiences = props.experiences ? props.experiences : data.work_experience;

    console.log(personalInfo, 'personalInfo')

    // cv_job_title
    // manager_name
    // company_name
    return (
        <>
            <div className="resume-container-4">
                {/* {personalInfo?.firstName && 'Mr'} */}

                <div className="resume-header-4 pb-0 mb-0">{`${personalInfo?.firstName} ${personalInfo?.lastName}`}</div>
                <p className="p-0 font-weight-bold">{personalInfo?.cv_job_title}</p>


                <div className="d-flex justify-content-between">
                    <div className="resume-contact-To-4">
                        {personalInfo?.manager_name && "To"}
                        <p className="m-0">{personalInfo?.manager_name}</p>
                        <p className="m-0">{personalInfo?.company_name}</p>


                        {/* <p>{personalInfo?.firstName} {personalInfo?.lastName}</p>
                        <p>{personalInfo?.cv_job_title}</p> */}

                    </div>


                    <div className="resume-contact-info-4">
                        <p>{personalInfo?.country_code} {personalInfo?.phone}</p>
                        <p>{personalInfo?.email}</p>
                        <p>{personalInfo?.address}</p>
                        <p>{personalInfo?.city} {personalInfo?.state}  {personalInfo?.postalCode}</p>
                        {personalInfo?.postalCode &&
                            <p>{personalInfo?.country}</p>}
                    </div>
                </div>

                <div className="resume-content-4">

                    <div dangerouslySetInnerHTML={{ __html: personalInfo?.objective }}></div>

                </div>
            </div>
        </>

    )

}