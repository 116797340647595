import React, { useEffect, useState } from "react";

import { useParams } from "react-router";
import { Get_ResumeWith_uuid } from "../api/services/resumeService";
import { templates } from "../Data/templates";
import { Alltemplates } from "../Data/AllTemplates";
// const mapStateToProps = (state) => ({
//     selectedTemplateId: state?.selectedTemplateReducer?.selectedTemplateId,
//   });
  
//   const mapDispatchToProps = (dispatch) => ({
//     setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
//     setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
//     onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
//     setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
//     onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
//     onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
//   });

const ResumeView=()=>{
   const params = useParams();
   const [resumeData,setResumeData]=useState([]);
   
    const getTemplate = (resume, index) => {
        let template = Alltemplates?.find(
          (eachTemplate) => eachTemplate?.id == resume?.template_id
        );
        const TemplateComp = React.cloneElement(template?.template, {
          personalInfo: resume?.personalInfo,
          experiences: resume?.experiences,
          educationInfo: resume?.educationInfo,
          skills: resume?.skills,
          hobbies:resume?.hobbies,
          key: resume?.id,
          index: index,
        });
        return TemplateComp;
      };

  const GetResumeWith_uuid= async()=>{
    try{
       const response = await Get_ResumeWith_uuid(params?.uuid);
       console.log(response.data)

       const updatedArray = response?.data?.map((item)=>({
        educationInfo:item?.education,
        experiences:item?.work_experience,
        skills:item?.skills?.map((val)=>val?.skills),
        hobbies:item?.hobbies?.map((val)=>val?.hobbies),
        template_id: item?.template_id,
        id:item?.id,
        personalInfo: {
        address: item?.address,
        country: item?.country?.country_name,
        country_id: item?.country_id,
        city:item?.city?.city_name,
        city_id: item?.city_id,
        email: item?.email,
        firstName: item?.first_name,
        lastName: item?.last_name,
        phone: item?.phone,
        country_code:item?.country_code,
        nationality: item?.nationality,
        website: item?.website,
        dob: item?.dob,
        objective:item?.objective,
        postalCode: item?.postal_code,
        profile:item?.profile,
        state: item?.state?.state_name,
        state_id: item?.state_id,
        cv_job_title: item?.cv_job_title,
        manager_name: item?.manager_name,
        company_name: item?.company_name,
      },     
    }));

    setResumeData(updatedArray);
    // console.log("updatedArray",updatedArray)

    }catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{
    GetResumeWith_uuid();
  },[params?.uuid]);

  return(<>
     <div className="container mt-5 d-flex justify-content-center">
      {
       resumeData?.length > 0 &&
        resumeData?.map((resume,index)=>{
        return(<>
         {getTemplate(resume, index)}      
        </>)
       }) 
     }
     </div>
  </>)    
 };

export default ResumeView;