import * as actionTypes from "./ActionTypes";

const initialSelectedTemplateState = {
    selectedTemplateId: null,
    selectedResumeId: null,
};

const initialPersonalInfoState = {
    personalInfo: {
        profileImg: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        address: "",
        city: "",
        state: "",
        postalCode: "",
        objective: "",
        nationality: "",
        website: "",
        dob: "",
    },
};

const initialWorkExperienceState = {
    experiences: [
        {
            // id: 1,
            // jobTitle: "",
            // organizationName: "",
            // startYear: "",
            // endYear: "",
            id: 1,
            job_title: "",
            org_name: "",
            start_year: "",
            end_year: "",
            job_location: '',
            description: ""
        },
    ],
};

const initialEducationState = {
    educationInfo: {
        domain: "",
        university: "",
        degree: "",
        startYear: "",
        endYear: "",
        education_location: "",
    },
};

const initialSkillsState = {
    skills: [""],
};

export const selectedTemplateReducer = (
    state = initialSelectedTemplateState,
    action
) => {
    switch (action.type) {
        case actionTypes.SelectTemplate:
            return { ...state, selectedTemplateId: action.payload };
        case actionTypes.SelectResume:
            return { ...state, selectedResumeId: action.payload };
        default:
            return state;
    }
};


export const personalInfoReducer = (
    state = initialPersonalInfoState,
    action
) => {
    switch (action.type) {
        case actionTypes.AddPersonalInfo:
            return {
                ...state,
                personalInfo: { ...state.personalInfo, ...action.payload },
            };
        default:
            return state;
    }
};

export const workExpReducer = (
    state = initialWorkExperienceState,
    action
) => {
    switch (action.type) {
        case actionTypes.AddExperience:
            return {
                ...state,
                experiences: [...state.experiences, action.payload],
            };
        case actionTypes.AddExperienceAll:
            return {
                ...state,
                experiences: action.payload,
            };
        default:
            return state;
    }
};

export const SkillReducer = (state = initialSkillsState, action) => {
    switch (action.type) {
        case actionTypes.AddNewSkills:
            return { ...state, skills: [...state.skills, ""] };
        case actionTypes.EditSkill: {
            return {
                ...state,
                skills: action.payload,
            };
        }
        case actionTypes.DeleteSkill: {
            const newSkills = state.skills.filter(
                (skill, id) => id !== action.payload
            );

            return { ...state, skills: newSkills };
        }
        default:
            return state;
    }
};

export const EducationInfo = (
    state = initialEducationState,
    action
) => {
    switch (action.type) {
        case actionTypes.AddEducation:
            return { ...state, educationInfo: action.payload };
        default:
            return state;
    }
};


const initialHobbiesState = {
    hobbies: [""],
};

export const HobbyReducer = (state = initialHobbiesState, action) => {
    switch (action.type) {
        case actionTypes.AddNewHobbies:
            return { ...state, hobbies: [...state.hobbies, ""] };
        case actionTypes.EditHobbies:
            return { ...state, hobbies: action.payload };
        case actionTypes.DeleteHobbies:
            return { ...state, hobbies: state.hobbies.filter((_, i) => i !== action.payload) };
        default:
            return state;
    }
};
