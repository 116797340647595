import "../../assets/Styles/Footer.css";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { SlSocialSpotify } from "react-icons/sl";
import crpwImg from "../../assets/Images/cprw.webp";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer bg-dark text-light py-4">
      <div className="container">
        {/* Footer Columns */}
        <div className="row">
          {/* Social Media */}
          <div className="col-md-4 col-sm-6 mb-4">
            <h5 className="footer-logo">Connect with us on social media</h5>
            <ul className="list-inline footer-icons mt-3">
              <li className="list-inline-item">
                <TiSocialLinkedin size={25} />
              </li>
              <li className="list-inline-item">
                <AiFillYoutube size={25} />
              </li>
              <li className="list-inline-item">
                <FaPinterestP size={25} />
              </li>
              <li className="list-inline-item">
                <IoLogoInstagram size={25} />
              </li>

            </ul>

            <ul className="list-inline footer-icons">
              <li className="list-inline-item">
                <FaFacebookF size={25} />
              </li>
              <li className="list-inline-item">
                <FaTiktok size={25} />
              </li>
              <li className="list-inline-item">
                <SlSocialSpotify size={25} />
              </li>
            </ul>
          </div>

          {/* Jobseekers */}
          <div className="col-md-2 col-sm-6 mb-4">
            <h5 style={{ color: 'gray' }}>Jobseekers</h5>
            <ul className="list-unstyled">
              <li><Link to="#" className="text-light">Create A Resume</Link></li>
              <li><Link to="#" className="text-light">Resume Example</Link></li>
              <li><Link to="/templatecard" className="text-light">Resume Templates</Link></li>
              <li><Link to="/cvtemplatecard" className="text-light">Cover Letter Templates</Link></li>
              <li><Link to="#" className="text-light">Job Search</Link></li>
            </ul>
          </div>

          {/* Career Resources */}
          <div className="col-md-2 col-sm-6 mb-4">
            <h5 style={{ color: 'gray' }}>Career Resources</h5>
            <ul className="list-unstyled">
              <li><Link to="#" className="text-light">Resume Help</Link></li>
              <li><Link to="#" className="text-light">Job Interview</Link></li>
              <li><Link to="#" className="text-light">Career</Link></li>
              <li><Link to="#" className="text-light">Cover Letter</Link></li>
              <li><Link to="/blog" className="text-light">Blog</Link></li>
            </ul>
          </div>

          {/* Our Company */}
          <div className="col-md-2 col-sm-6 mb-4">
            <h5 style={{ color: 'gray' }}>Our Company</h5>
            <ul className="list-unstyled">
              <li><Link to="#" className="text-light">About Us</Link></li>
              {/* <li><Link to="#" className="text-light">Pricing</Link></li> */}
              <li><Link to="#" className="text-light">Sponsorship Program</Link></li>
              <li><Link to="#" className="text-light">Media Kit</Link></li>
              <li><Link to="#" className="text-light">Affiliates</Link></li>
            </ul>
          </div>

          {/* Support */}
          <div className="col-md-2 col-sm-6 mb-4">
            <h5 style={{ color: 'gray' }}>Support</h5>
            <ul className="list-unstyled">
              <li><Link to="#" className="text-light">FAQ</Link></li>
              <li><Link to="#" className="text-light">Contact Us</Link></li>
              <li><Link to="#" className="text-light">Terms Of Service</Link></li>
              <li><Link to="#" className="text-light">Privacy</Link></li>
              <li><Link to="#" className="text-light">Right of withdrawal</Link></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="row align-items-center mt-4">
          <div className="col-md-6 text-center text-md-start mb-2 mb-md-0">
            <span>🇺🇸 International</span> | <span>Copyright © 2025 - <Link to="https://idea2reality.tech/" target="_blank"> resume.idea2reality</Link></span>
          </div>
          <div className="col-md-2 text-center mb-2 mb-md-0">
            <img src={crpwImg} alt="CPRW Logo" className="img-fluid badge-bottom" />
          </div>
          <div className="col-md-4 text-center text-md-end">
            <span>
              More than a resume builder. Part of
            </span>
            <span className="brand-bottom fw-bold"> <Link to="https://idea2reality.tech/" target="_blank"> Resume.idea2reality </Link> </span>
          </div>
        </div>
      </div>
    </footer >
  );
};

export default Footer;
