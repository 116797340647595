import React, { useEffect, useState } from "react";
import "../assets/Styles/MyResumes.css";
import { Button } from "@mui/material";
import DarkEffect from "../Components/DarkEffect";
import { templates } from "../Data/templates";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import JsPDF from "jspdf";
import { connect } from "react-redux";
import {
  ADDEXPERIENCEALL,
  ADDEDUCATION,
  ADDPERSONALINFO,
  EDITSKILL,
  SELECTRESUME,
  SELECTTEMPLATE,
  EDITHOOBY,
} from "../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { getResume } from "../api/services/resumeService";
import DeleteResumeWarningMdl from "../Components/MyResume/DeleteResumeWarningMdl";
import { handleDeleteResume } from "../api/services/memberDashboardService";
import { toast } from "react-toastify";
import { Loader1 } from "../Components/Loader/Loader1";
import { Cvtemplates } from "../Data/CvTemplates";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  padding: theme?.spacing(1),
}));

//For mapping state with props.
const mapStateToProps = (state) => ({
  selectedTemplateId: state?.selectedTemplateReducer?.selectedTemplateId,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTemplateId: (id) => dispatch(SELECTTEMPLATE(id)),
  setSelectedResumeId: (id) => dispatch(SELECTRESUME(id)),
  onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
  setADDEXPERIENCEALL: (experiences) => dispatch(ADDEXPERIENCEALL(experiences)),
  onADDEDUCATION: (details) => dispatch(ADDEDUCATION(details)),
  onEDITSKILL: (skills) => dispatch(EDITSKILL(skills)),
  onEditHobby: (hobbies) => dispatch(EDITHOOBY(hobbies))
});

const MyCv = (props) => {
  const [resumes, setResumes] = useState([]);
  const [deleteDataMdl, setDeleteDataMdl] = useState(false);
  const [deleteResumeId, setDeleteResumeId] = useState("");
  const [loading, setLoading] = useState(false);

  //checking for available resumes in localStorage
  useEffect(() => {
    const newResumes = window.localStorage.getItem("resumes")
      ? JSON.parse(window.localStorage.getItem("resumes"))
      : [];
    // console.log("newResumes", newResumes);
    // setResumes(newResumes);
  }, []);

  const navigate = useNavigate();

  const getTemplate = (resume, index) => {
    // let template = templates?.find(
    //   (eachTemplate) => eachTemplate?.id == resume?.template_id
    // );
    // const TemplateComp = React.cloneElement(template?.template, {
    //   personalInfo: resume?.personalInfo,
    //   experiences: resume?.experiences,
    //   educationInfo: resume?.educationInfo,
    //   skills: resume?.skills,
    //   key: resume?.id,
    //   index: index,
    // });
    // return TemplateComp;

    let template = Cvtemplates?.find(
      (eachTemplate) => eachTemplate?.id == resume?.template_id
    );
    // const TemplateComp = React.cloneElement(template?.template, {
    //   personalInfo: resume?.personalInfo,
    //   experiences: resume?.experiences,
    //   educationInfo: resume?.educationInfo,
    //   skills: resume?.skills,
    //   key: resume?.id,
    //   index: index,
    // });
    // return TemplateComp;

    const TemplateComp = template?.template && React.isValidElement(template.template)
      ? React.cloneElement(template.template, {
        personalInfo: resume?.personalInfo,
        experiences: resume?.experiences,
        educationInfo: resume?.educationInfo,
        skills: resume?.skills,
        hobbies:resume?.hobbies,
        key: resume?.id,
        index: index,
      })
      : null;  // Return `null` if `template?.template` is not valid
    return TemplateComp;
  };

  const handleDelete = async () => {
    try {
      const response = await handleDeleteResume(deleteResumeId);
      if (response?.success) {
        toast.success(response?.message, {
          position: "top-center",
        });

        getResumeData();
        setDeleteDataMdl(false);
      } else {
        toast.error(response?.error, {
          position: "top-center",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // For deleteing the resume
  const deleteResume = (resume) => {
    setDeleteDataMdl(true);
    setDeleteResumeId(resume?.id);
    // let resumes = window.localStorage.getItem("resumes");
    // let newResumes = JSON.parse(resumes);
    // const newSetOfResumes = newResumes.filter((eachResume) => {
    //   return eachResume.id !== resume.id;
    // });
    // window.localStorage.setItem("resumes", JSON.stringify(newSetOfResumes));
    // setResumes(newSetOfResumes);
  };

  // For Downloading the Resume
  const downloadResume = (id) => {
    const report = new JsPDF("p", "pt", "a4");
    report.html(document.getElementById(`${id}report`)).then(() => {
      report.save(`resume.pdf`);
    });
  };

  const setUserData = (resume) => {
    //for setting personal info
    props.onADDPERSONALINFO(resume.personalInfo);
    //for setting work experience
    props.setADDEXPERIENCEALL(resume.experiences);
    //for setting education information
    props.onADDEDUCATION(resume.educationInfo);
    //for setting skills
    props.onEDITSKILL(resume.skills);

    props.onEditHobby(resume.hobbies);
  };

  const navigateToFillDetails = (resume) => {
    props.setSelectedTemplateId(resume?.template_id);
    props.setSelectedResumeId(resume?.id);
    setUserData(resume);
    // navigate("/template/FillDetails");
    navigate('/cvtemplatecard/FillDetails')
  };

  const getResumeData = async () => {
    setLoading(true)
    try {
      const response = await getResume();
      const updatedArray = response
        ?.filter((item) => item.type === 'cv')
        .map((item) => ({
          educationInfo: item?.education,
          experiences: item?.work_experience,
          skills: item?.skills?.map((val) => val?.skills),
          hobbies: item?.hobbies?.map((val) => val?.hobbies),
          template_id: item?.template_id,
          id: item?.id,
          type: item.type,
          personalInfo: {
            address: item?.address,
            country: item?.country?.country_name,
            country_id: item?.country_id,
            city: item?.city?.city_name,
            city_id: item?.city_id,
            email: item?.email,
            firstName: item?.first_name,
            lastName: item?.last_name,
            nationality: item?.nationality,
            website: item?.website,
            dob: item?.dob,
            phone: item?.phone,
            country_code: item?.country_code,
            objective: item?.objective,
            postalCode: item?.postal_code,
            profile: item?.profile,
            state: item?.state?.state_name,
            state_id: item?.state_id,
            cv_job_title: item?.cv_job_title,
            manager_name: item?.manager_name,
            company_name: item?.company_name,
          },
        }));
      setResumes(updatedArray);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResumeData();
  }, []);

  return (
    <>
      {deleteDataMdl && (
        <DeleteResumeWarningMdl
          setDeleteDataMdl={setDeleteDataMdl}
          handleDelete={handleDelete}
        />
      )}
      <div className="container-fluid d-flex justify-content-between py-5 px-5 ">
        <h2 style={{ fontSize: "1.5rem", fontWeight: "700" }}>
          My Cover Letter
        </h2>

        <div className="d-flex gap-2">
          <button
            style={{
              backgroundColor: "#951dc1",
              color: "#ffffff",
              padding: "8px 16px",
              borderRadius: "8px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("/Cvtemplatecard")}
          >
            + Create CV
          </button>
        </div>
      </div>
      <div className="my-resumes">
        {
          loading ? <div className="d-flex justify-content-center py-3"> <Loader1 /> </div> :
            <Box sx={{ flexGrow: 1 }}>
              <Stack
                sx={{
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: {
                    sm: "1fr 1fr ",
                    md: "1fr 1fr ",
                    lg: "1fr 1fr 1fr",
                    xl: "1fr 1fr 1fr",
                  },
                  gridGap: "30px",
                }}>
                {resumes?.length >= 1 ? (
                  resumes?.map((resume, index) => {
                    return (
                      <Grid
                        item
                        className={`show-resume`}
                        id={`${index}show-resume`}
                        margin={2}
                        key={index}>
                        <Item className="itemBox" id={`${index}ITEM`}>
                          {getTemplate(resume, index)}
                          <DarkEffect />
                          <div className="use-template-btn-cont">
                            <Button
                              className="use-template-btn"
                              onClick={() => { downloadResume(index) }}
                              size="medium" variant="contained">
                              Download
                            </Button>
                            <Button
                              className="use-template-btn"
                              onClick={() => { deleteResume(resume) }}
                              size="medium" variant="contained">
                              Delete
                            </Button>
                            <Button
                              className="use-template-btn"
                              onClick={() => navigateToFillDetails(resume)}
                              size="medium" variant="contained">
                              Edit Template
                            </Button>
                          </div>
                        </Item>
                      </Grid>
                    );
                  })
                ) : (
                  <div className="no-resumes-container">
                    <SentimentVeryDissatisfiedIcon fontSize="medium" />
                    <p className="no-resumes-text">Empty</p>
                  </div>
                )}
              </Stack>
            </Box>
        }
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCv);
