// import React, { useState } from "react";
// import { Paper, Divider, Button } from "@mui/material";
// import InputComp from "./InputComp";
// import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import BackNextBtnComp from "./BackNextBtnComp";
// import { useForm } from "react-hook-form";
// import { connect } from "react-redux";
// import { ADDNEWSKILLS, EDITSKILL, DELETESKILL } from "../Redux/Actions";
// import "../assets/Styles/KeySkillComp.css";

// const mapStateToProps = (state) => ({
//   skills: state.SkillReducer.skills,
// });

// const mapDispatchToProps = (dispatch) => ({
//   onAddNewSkill: () => dispatch(ADDNEWSKILLS()),
//   onEditSkill: (skills) => dispatch(EDITSKILL(skills)),
//   onDeleteSkill: (index) => dispatch(DELETESKILL(index)),
// });

// function KeySkill(props) {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//   } = useForm();
//   const [loading, setLoading] = useState(false);

//   const handlePreview = (data) => {
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       props.setTab(props.tab + 1);
//     }, 1000);
//   };

//   const handleBack = () => {
//     props.setTab(props.tab - 1);
//   };

//   const onEditSkill = (value, id) => {
//     const newSkill = props.skills.map((skill, index) => {
//       if (index === id) {
//         return value;
//       } else return skill;
//     });

//     props.onEditSkill(newSkill);
//   };

//   return (
//     <Paper className="keySkillPaper col-9">
//       <h2 className="key-skills-heading">Key Skills</h2>
//       <Divider />
//       <form onSubmit={handleSubmit(handlePreview)}>
//         <div className="key-skills-form-fields">
//           {props?.skills?.map((skill, index) => {
//             return (
//               <div key={index} className="key-input-with-delete">
//                 <InputComp
//                   title={`skill ${index + 1}`}
//                   asterisk={true}
//                   type="text"
//                   name={`skill${index + 1}`}
//                   placeholder={"Skill"}
//                   maxLength={"30"}
//                   register={register}
//                   multiline={false}
//                   value={skill}
//                   setValue={(skill) => onEditSkill(skill, index)}
//                   error={errors[`skill${index + 1}`] ? true : false}
//                   errorMessage={
//                     errors[`skill${index + 1}`]
//                       ? errors[`skill${index + 1}`].message
//                       : null
//                   }
//                 />
//                 {props.skills.length === 1 ? null : (
//                   <DeleteOutlineOutlinedIcon
//                     sx={{ marginLeft: "10px", marginTop: "25px", color: "#f14747" }}
//                     onClick={() => props.onDeleteSkill(index)}
//                   />
//                 )}
//               </div>
//             );
//           })}
//         </div>

//         {props.skills.length >= 6 ? null : (
//           <Button
//             className="add-new-btn"
//             variant="text"
//             onClick={props.onAddNewSkill}
//             type="button"
//           >
//             Add new
//           </Button>
//         )}

//         <Divider className="key-skills-divider" />
//         <BackNextBtnComp
//           onNext={handlePreview}
//           loading={loading}
//           tab={props.tab}
//           onBack={handleBack}
//           nextTitle={"Preview"}
//           backTitle={"Back"}
//         />
//       </form>
//     </Paper>
//   );
// }

// export default connect(mapStateToProps, mapDispatchToProps)(KeySkill);


import React, { useState } from "react";
import { Paper, Divider, Button } from "@mui/material";
import InputComp from "./InputComp";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BackNextBtnComp from "./BackNextBtnComp";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ADDNEWSKILLS, EDITSKILL, DELETESKILL, ADDNEWHOOBIES, EDITHOOBY, DELETEHOOBY } from "../Redux/Actions";
import "../assets/Styles/KeySkillComp.css";

const mapStateToProps = (state) => ({
  skills: state.SkillReducer.skills,
  hobbies: state.HobbyReducer.hobbies,
});

const mapDispatchToProps = (dispatch) => ({
  onAddNewSkill: () => dispatch(ADDNEWSKILLS()),
  onEditSkill: (skills) => dispatch(EDITSKILL(skills)),
  onDeleteSkill: (index) => dispatch(DELETESKILL(index)),

  onAddNewHobby: () => dispatch(ADDNEWHOOBIES()),
  onEditHobby: (hobbies) => dispatch(EDITHOOBY(hobbies)),
  onDeleteHobby: (index) => dispatch(DELETEHOOBY(index)),
});


function KeySkill(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const handlePreview = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab - 3);
    }, 1000);

  };

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  const onEditItem = (value, id) => {
    const newItems = props.items.map((item, index) =>
      index === id ? value : item
    );
    props.onEditItem(newItems);
  };


  const onEditSkill = (value, id) => {
    const newSkill = props.skills.map((skill, index) => {
      if (index === id) {
        return value;
      } else return skill;
    });

    props.onEditSkill(newSkill);
  };


  const onEditHobby = (value, id) => {
    const newHobby = props.hobbies.map((hobby, index) => {
      if (index === id) {
        return value;
      } else return hobby;
    });

    props.onEditHobby(newHobby);
  };
  return (
    <>

      <div className="keySkillPaper p-3">
        {/* <Paper className="keySkillPaper col-9"> */}
        <h2 className="key-skills-heading">Key Skills</h2>
        <Divider />
        <form onSubmit={handleSubmit(handlePreview)}>
          <div className="key-skills-form-fields">
            {props?.skills?.map((skill, index) => {
              return (
                <div key={index} className="key-input-with-delete">
                  <InputComp
                    title={`Skill ${index + 1}`}
                    asterisk={true}
                    type="text"
                    name={`skill${index + 1}`}
                    placeholder={"Skill"}
                    maxLength={"30"}
                    register={register}
                    multiline={false}
                    value={skill}
                    setValue={(skill) => onEditSkill(skill, index)}
                    error={errors[`skill${index + 1}`] ? true : false}
                    errorMessage={
                      errors[`skill${index + 1}`]
                        ? errors[`skill${index + 1}`].message
                        : null
                    }
                  />
                  {props.skills.length === 1 ? null : (
                    <DeleteOutlineOutlinedIcon
                      sx={{ marginLeft: "10px", marginTop: "25px", color: "#f14747" }}
                      onClick={() => props.onDeleteSkill(index)}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {props.skills.length >= 6 ? null : (
            <Button
              className="add-new-btn"
              variant="text"
              onClick={props.onAddNewSkill}
              type="button"
            >
              Add new
            </Button>
          )}
          <Divider className="key-skills-divider" />
          <h2 className="key-skills-heading">Hobbies</h2>
          <div className="key-skills-form-fields">
            {props?.hobbies?.map((hobby, index) => {
              return (
                <div key={index} className="key-input-with-delete">
                  <InputComp
                    title={`Hobby ${index + 1}`}
                    asterisk={true}
                    type="text"
                    name={`hobby${index + 1}`}
                    placeholder={"Hobby"}
                    maxLength={"30"}
                    register={register}
                    multiline={false}
                    value={hobby}
                    setValue={(hobby) => onEditHobby(hobby, index)}
                    error={errors[`hobby${index + 1}`] ? true : false}
                    errorMessage={
                      errors[`hobby${index + 1}`]
                        ? errors[`hobby${index + 1}`].message
                        : null
                    }
                  />
                  {props.hobbies.length === 1 ? null : (
                    <DeleteOutlineOutlinedIcon
                      sx={{ marginLeft: "10px", marginTop: "25px", color: "#f14747" }}
                      onClick={() => props.onDeleteHobby(index)}
                    />
                  )}
                </div>
              );
            })}
          </div>
          {props.hobbies.length >= 6 ? null : (
            <Button
              className="add-new-btn"
              variant="text"
              onClick={props.onAddNewHobby}
              type="button"
            >
              Add new
            </Button>
          )}

          <BackNextBtnComp
            onNext={handlePreview}
            loading={loading}
            tab={props.tab}
            onBack={handleBack}
            nextTitle={"Preview"}
            backTitle={"Back"}
          />
        </form>
        {/* </Paper> */}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(KeySkill);
