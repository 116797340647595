// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue, Grid } from "@chakra-ui/react";
// import Card from "components/card/Card.js";
import Card from "../../../../components_sec/card/Card";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );


  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Grid
      // templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} // Adjust for different screen sizes
      // gap={4} // Gap between grid items
      // w='100%' // Ensure grid takes full width
      // mt='26px' // Margin at the top
      >
        <div>
          <Box
            bg={`url(${banner})`}
            bgSize='cover'
            borderRadius='16px'
            h='131px'
            w='100%'
          />
          <Avatar
            mx='auto'
            src={`https://resumecv.idea2reality.tech/storage/userProfile/${avatar}`}
            h='87px'
            w='87px'
            mt='-43px'
            border='4px solid'
            borderColor={borderColor}
          />
          <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
            {name}
          </Text>

          {/* <Text color={textColorSecondary} fontSize='sm'>
        {job}
      </Text> */}

          {/*<Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex>*/}
        </div>
      </Grid>
    </Card>
  );
}
