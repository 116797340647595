import React from 'react';
import { data } from "../Data/data";
import "../assets/Styles/Template7.css";
import SliderProgress from '../Components/ProgressBar';
import { FaUser } from "react-icons/fa";
import { BsHandbagFill } from "react-icons/bs";
import { MdMenuBook } from "react-icons/md";
import { PiListHeartFill } from "react-icons/pi";
import { SiHyperskill } from "react-icons/si";






export default function Template6(props) {

    const personalInfo = props.personalInfo ? props.personalInfo : data.personal_info;

    const skills = props.skills ? props.skills : data.key_skills;

    const hobbies = props.hobbies ? props.hobbies : data.key_hobbies;

    const educationInfo = props.educationInfo ? props.educationInfo : data.education_details;

    const experiences = props.experiences ? props.experiences : data.work_experience;


    const formatDate = (dateString) => {
        if (!dateString) return "Invalid Date"; // Check for null, undefined, or empty string

        const date = new Date(dateString);

        if (isNaN(date.getTime())) return "Invalid Date"; // Check for invalid dates

        const day = String(date.getDate()).padStart(2, '0');
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };



    const formatDateToMMYY = (dateString) => {
        if (!dateString || typeof dateString !== "string") return "Invalid Date";

        const [yy, m, d] = dateString.split('-') || [];  // Avoid undefined destructuring
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const monthIndex = parseInt(m, 10) - 1; // Convert month to 0-based index

        if (isNaN(monthIndex) || monthIndex < 0 || monthIndex > 11) return "Invalid Date";

        return `${months[monthIndex]} ${yy}`;
    };

    // const filteredSkills = skills?.filter(skill => skill?.trim() !== "");
    // const filteredHobbies = hobbies?.filter(hobbie => hobbie?.trim() !== "");
    const filteredSkills = skills?.filter(
        skill => typeof skill === 'string' && skill.trim() !== ""
    );

    const filteredHobbies = hobbies?.filter(
        hobbie => typeof hobbie === 'string' && hobbie.trim() !== ""
    );


    const hasWorkExperience = experiences.length > 0 && experiences.some(item =>
        Object.entries(item).some(([key, value]) => key !== 'id' && value && value !== '')
    )


    return (
        <>
            <div className="resume-container-7">
                {/* {personalInfo?.firstName && 'Mr'} */}
                <h1 className="resume-title-7">{personalInfo?.firstName} {personalInfo?.lastName}</h1>

                {personalInfo?.objective && <p className="resume-summary-7" dangerouslySetInnerHTML={{ __html: personalInfo?.objective }}>
                </p>}
                {(personalInfo?.email || personalInfo?.dob || personalInfo?.nationality || personalInfo?.postalCode || personalInfo?.phone || personalInfo?.website) &&
                    <div className="resume-section-7 personal-info-7">
                        <h2 className="section-title-7 gap-2">
                            <FaUser size={22} />
                            Personal Information</h2>
                        <div className="section-content-7">
                            <p className="p-0 m-0">
                                {personalInfo?.dob && (<> <strong>Date of birth:</strong> {formatDate(personalInfo?.dob)} </>)}
                                {personalInfo?.nationality && (<> <strong>Nationality:</strong> {personalInfo?.nationality} </>)}
                            </p>
                            {personalInfo?.postalCode && (
                                <p className="p-0 m-0">
                                    <strong>Address:</strong> {personalInfo?.address} {personalInfo?.city} {personalInfo?.state} {personalInfo?.country} {personalInfo?.postalCode}
                                </p>
                            )}
                            <p className="p-0 m-0">
                                {personalInfo?.phone && (<>
                                    <strong>Phone number:</strong> {personalInfo?.country_code} {personalInfo?.phone}
                                </>)}
                                {personalInfo?.email && (
                                    <>
                                        <strong>Email address:</strong> {personalInfo?.email}
                                    </>)}
                            </p>
                            {personalInfo?.website && (
                                <p className="p-0 m-0">
                                    <strong>Web:</strong> <a href="#" className="resume-link-7">{personalInfo?.website}</a>
                                </p>
                            )}
                        </div>
                    </div>
                }
                {/* ------------------------------------------------------------------------------------------ */}

                {hasWorkExperience &&
                    <div className="resume-section-7 exprience-info-7">
                        <h2 className="section-title-7 gap-2"><BsHandbagFill size={22} /> Experience</h2>

                        {experiences?.map((value) => (
                            <div className="section-content-7">
                                <div className="exprience-7-card">
                                    <h5>{value?.org_name}</h5>
                                    <p>{value?.job_title}</p>
                                    <span>{formatDateToMMYY(value?.start_year)} - {formatDateToMMYY(value?.end_year)} {value?.job_location}</span>
                                    <p>
                                        {value?.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                }
                {/* ------------------------------------------------------------------------------------------ */}

                {/* <div className="resume-section-7 personal-info-7">
                    <h2 className="section-title-7">Habilidades</h2>
                    <div className="section-content-7">
                        <div className="Habilidades-7-card">
                            <h5>– CONOCIMIENTOS INFORMÁTICOS</h5>
                            <div className="d-flex gap-4 mt-2">
                                <SliderProgress range={10} title='investigación en línea' />
                                <SliderProgress range={10} title='Word, PowerPoint, Excel' />
                                <SliderProgress range={7} title='Access, Outlook' />
                            </div>
                        </div>

                        <div className="Habilidades-7-card mt-3">
                            <h5>– DESTREZAS LINGÜÍSTICAS</h5>
                            <div className="d-flex gap-4 mt-2">
                                <SliderProgress range={8} title='Inglés' />
                                <SliderProgress range={10} title='Árabe' />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* ------------------------------------------------------------------------------------------ */}

                {Object.values(educationInfo).some(value => value && value !== '') &&
                    <div className="resume-section-7 personal-info-7">
                        <h2 className="section-title-7 gap-2"><MdMenuBook size={22} /> Education</h2>
                        {Array.isArray(educationInfo) && educationInfo?.map((value) => (
                            <div className="section-content-7" key={value?.id || value?.degree}>
                                <div className="exprience-7-card">
                                    <h5>{value?.degree}</h5>
                                    <span>{formatDateToMMYY(value?.start_year)} - {formatDateToMMYY(value?.end_year)} | {value?.education_location}</span>
                                    <p>{value?.university}</p>
                                    <p>
                                        {value.description}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                }

                {filteredSkills?.length > 0 &&
                    <div className="resume-section-7 exprience-info-7">
                        <h2 className="section-title-7 gap-2"><SiHyperskill size={22} /> Skills</h2>
                        <div className="section-content-7">

                            <ul className="resume-7-job-desc-skill">
                                {skills?.map((skill) => (
                                    <>
                                        <li>{skill}</li>
                                    </>
                                ))}
                            </ul>
                        </div>
                    </div>
                }


                {filteredHobbies?.length > 0 &&
                    <div className="resume-section-7 personal-info-7">
                        <h2 className="section-title-7 gap-2"><PiListHeartFill size={22} /> Hobbies</h2>
                        <div className="section-content-7">

                            <ul className="resume-7-job-desc-skill">
                                {hobbies?.map((hobbie) => (
                                    <>
                                        <li>{hobbie}</li>
                                    </>
                                ))}
                            </ul>
                        </div>
                    </div>
                }

            </div>
        </>

    )

}