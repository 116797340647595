import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// import Usa from "assets/img/dashboards/usa.png";
import Usa from "../../../assets/img/dashboards/usa.png";
// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniCalendar from "../../../components_sec/calendar/MiniCalendar";
import "../../../assets/css/Default.css";
import MiniStatistics from "../../../components_sec/card/MiniStatistics";
import IconBox from "../../../components_sec/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import ComplexTable from "../../../views/admin/default/components/ComplexTable";
import { columnsDataComplex } from "../../../views/admin/default/variables/columnsData";
import tableDataComplex from "../../../views/admin/default/variables/tableDataComplex.json";
import { FaEye, FaPlus } from "react-icons/fa";
import { getDashboardData } from "../../../api/services/memberDashboardService";
import { useNavigate } from "react-router";
import jsPDF from "jspdf";
export default function UserReports() {
  const [dashboardData, setDashboardData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [typeResume, setTypeResume] = useState("resume")
  const navigate = useNavigate();

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const getDashboard_Data = async () => {
    setLoading(true);
    try {
      const response = await getDashboardData(currentPage);
      const list_Reusme = response?.data?.resume.data;
      // const updatedArray = list_Reusme
      //   ?.filter((item) => item.type === typeResume)
      // console.log(updatedArray)

      const data = response?.data;

      const updatedData = {
        ...data,
        resume: {
          ...data.resume,
          data: data.resume.data.filter((item) => item.type === typeResume)
        }
      };

      setDashboardData(updatedData);
      // setDashboardData(data);
      setTableData(data?.resume?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // console.log("td",tableData)

  useEffect(() => {
    getDashboard_Data();
  }, [currentPage, typeResume]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="400px">
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color='#6f21b7' />
              }
            />
          }
          name="Total Resume"
          value={dashboardData?.total_resumes}
        />

        {/* <MiniStatistics
          startContent={
          <IconBox
            w='56px'
            h='56px'
            bg={boxBg}
            icon={<Icon w='32px' h='32px' as={FaEye} color={brandColor} />}/>
          }
          name='Total Views Resume'
          value='2349'
        /> */}

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdFileCopy} color='#6f21b7' />
              }
            />
          }
          name="Total Cover Letter"
          value={dashboardData?.my_resumes}
        />
      </SimpleGrid>

      <div className="rctb-div">
        <div className="rctb-first-div">
          <h2>
            Resumes & Cover Letters
          </h2>
          <button
            onClick={() => navigate(typeResume === "resume" ? "/templateCard" : "/CvtemplateCard")}
          >
            + Create New
          </button>
        </div>
        <div style={{ display: "flex", borderBottom: "2px solid #e0e0e0" }}>
          <span className={typeResume == 'resume' ? "active-type-resume" : "deactive-type-resume"}
            onClick={() => setTypeResume("resume")}
          >
            Resumes
          </span>
          <span
            className={typeResume == 'cv' ? "active-type-resume" : "deactive-type-resume"}
            onClick={() => setTypeResume("cv")}

          >
            Cover Letters
          </span>
        </div>
      </div>

      {
        // dashboardData?.resume?.data?.length > 0 && (
        <ComplexTable
          // columnsData={columnsDataComplex}
          tableData={dashboardData?.resume}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          typeResume={typeResume}
        />
        // )
      }
    </Box >
  );
}
