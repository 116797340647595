import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";
import "../../../../assets/css/EditProfileForm.css";
import {
  get_EditProfile,
  getProfileDropdownVal,
  UpdateProfile,
} from "../../../../api/services/userService";
import AddressSection from "./AddressSection";
import PhoneInput from "react-phone-input-2";
import profileImg from "../../../../assets/Images/noProfile.jpg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

function EditProfileModal(props) {
  const { isOpen, onOpen, onClose, avatar, getUserData } = props;
  const [formData, setFormData] = useState({
    profile: "",
    f_name: "",
    registration_date: "",
    l_name: "",
    web_url: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    postal_code: "",
    country_id: "",
    state_id: "",
    city_id: "",
    description: "",
    industry_id: "",
    salarymin: "",
    seniority: "",
    salary_rate: "",
  });

  const [employmentType, setEmploymentType] = useState([]);
  const [mobileForm, setMobileForm] = useState({
    country_code: "",
    phone: "",
  });

  const [dropdownData, setDropdownData] = useState({
    employee_types: [],
    salaries: [],
    seniorities: [],
    industries: [],
  });

  const [profileUrl, setProfileUrl] = useState(null);
  const [errors, setErrors] = useState({});

  const GetEditProfile = async () => {
    try {
      const response = await get_EditProfile();
      const formInputData = response?.data?.data;
      setMobileForm({
        country_code: formInputData?.country_code,
        phone: formInputData?.phone,
      });
      const emp_type = formInputData?.employee_type?.map(
        (item) => item?.emp_type_id
      );
      // console.log("formInputData?.employee_type",emp_type)
      setEmploymentType(emp_type)


      const formattedDate = new Date(formInputData?.created_at).toISOString().slice(0, 10);
      setFormData({
        ...formInputData, registration_date: formattedDate
      });


    } catch (err) {
      console.log(err);
    }
  };

  const getDropdownData = async () => {
    try {
      const response = await getProfileDropdownVal();
      const data = response?.data?.data;
      setDropdownData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetEditProfile();
    getDropdownData();
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value, files, type } = e.target;
    if (type == "file") {
      const selectedFile = files[0];
      setFormData((prevData) => ({
        ...prevData,
        [name]: selectedFile,
      }));

      if (selectedFile) {
        const fileURL = URL.createObjectURL(selectedFile);
        setProfileUrl(fileURL);
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // console.log("formdata",formData)

  const validateForm = () => {
    let formErrors = {};

    if (!formData.profile) {
      formErrors.profile = "Profile is required";
    }

    if (!formData.f_name) {
      formErrors.f_name = "First name is required";
    }

    if (!formData.l_name) {
      formErrors.l_name = "Last name is required";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
    }

    // Phone Number Validation
    // if (!mobileForm?.phone) {
    //   formErrors.phone = "Phone number is required";
    // } else if (!/^\d{10}$/.test(mobileForm?.phone)) { // Assuming 10-digit phone numbers
    //   formErrors.phone = "Phone number must be 10 digits";
    // }

    // Postal Code Validation
    if (!formData.postal_code) {
      formErrors.postal_code = "Postal code is required";
    }

    // URL Validation
    if (
      formData.web_url &&
      !/^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,4}(\/[^\s]*)?$/i.test(formData.web_url)
    ) {
      formErrors.web_url = "Website URL is invalid";
    }

    // Country, State, City Validation
    if (!formData.country_id) {
      formErrors.country_id = "Country is required";
    }
    if (!formData.state_id) {
      formErrors.state_id = "State is required";
    }
    if (!formData.city_id) {
      formErrors.city_id = "City is required";
    }

    // Salary Minimum Validation
    if (!formData.salarymin) {
      formErrors.salarymin = "Minimum salary is required";
    }

    if (!formData.salary_rate) {
      formErrors.salary_rate = "Salary rate is required";
    }

    if (!formData.industry_id) {
      formErrors.industry_id = "Industry is required";
    }

    if (!formData.seniority) {
      formErrors.seniority = "Seniority level is required";
    }

    if (employmentType?.length <= 0) {
      formErrors.employee_type = "Employment Type is required";
    }
    if (!formData.description) {
      formErrors.description = "Description is required";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // return true if no errors
  };
  console.log(errors)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const fData = {
        ...formData,
        emp_type_id: JSON.stringify(employmentType),
      };
      try {
        const response = await UpdateProfile(fData);
        if (response?.data?.success) {
          toast.success(response?.data?.message, {
            position: "top-center",
          });
          getUserData();
          onClose();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Form validation failed:", errors);
    }
  };

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const handleEmploymentChange = (event) => {
    const { value, checked } = event.target;
    const numericValue = parseInt(value);
    if (checked) {
      setEmploymentType((prev) => [...prev, numericValue]);
    } else {
      setEmploymentType((prev) =>
        prev?.filter((item) => item !== numericValue)
      );
    }
  };

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent p={5}>
          <ModalHeader p={2}>Edit Your Profile</ModalHeader>
          <ModalCloseButton />

          <div className="container">
            <form onSubmit={handleSubmit}>
              {/* Profile Picture and Registration Date */}
              <div className="row g-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="profile">
                      Profile Picture<span className="astrik-icon">*</span>
                    </label>
                    <div className="my-2">
                      <Avatar
                        mx="auto"
                        // src={
                        //   profileUrl == null
                        //     ? `https://resumecv.idea2reality.tech/storage/userProfile/${formData.profile}`
                        //     : profileUrl
                        // }

                        src={
                          profileUrl
                            ? profileUrl
                            : formData?.profile
                              ? `https://resumecv.idea2reality.tech/storage/userProfile/${formData.profile}`
                              : profileImg
                        }

                        style={{
                          height: "87px",
                          width: "87px",
                          marginTop: "6px",
                          border: "4px solid",
                          borderColor: borderColor,
                        }}
                      />


                    </div>
                    <input
                      type="file"
                      className="form-control"
                      name="profile"
                      onChange={handleChange}
                    />
                    {errors.profile && (
                      <small className="text-danger">{errors.profile}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Registration Date<span className="astrik-icon">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="registration_date"
                      value={formData.registration_date}
                      onChange={handleChange}
                      disabled
                    />
                    {errors.registration_date && (
                      <small className="text-danger">
                        {errors.registration_date}
                      </small>
                    )}
                  </div>
                </div>
              </div>

              {/* First Name, Last Name, Email, Phone */}
              <div className="row g-3 mt-3">
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      First Name<span className="astrik-icon">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="f_name"
                      value={formData.f_name}
                      onChange={handleChange}
                    />
                    {errors.f_name && (
                      <small className="text-danger">{errors.f_name}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Last Name<span className="astrik-icon">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="l_name"
                      value={formData.l_name}
                      onChange={handleChange}
                    />
                    {errors.l_name && (
                      <small className="text-danger">{errors.l_name}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Email Address<span className="astrik-icon">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled
                    />
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Phone Number<span className="astrik-icon">*</span>
                    </label>
                    <PhoneInput
                      value={`${mobileForm?.country_code}${mobileForm?.phone}`}
                      name="phone"
                      className=""
                      inputClass="profilePhoneInput"
                      placeholder="phone"
                      disabled={true}
                    />
                    {errors.phone && (
                      <small className="text-danger">{errors.phone}</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Address */}
              <AddressSection
                formData={formData}
                setFormData={setFormData}
                errors={errors}
              />

              {/* Description */}
              <div className="form-group mt-3">
                <label>
                  Description<span className="astrik-icon">*</span>
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
                {errors.description && (
                  <small className="text-danger">{errors.description}</small>
                )}
              </div>

              {/* Website URL, Industry, Minimum Salary */}
              <div className="row g-3 mt-3">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Website URL</label>
                    <input
                      type="text"
                      className="form-control"
                      name="web_url"
                      value={formData.web_url}
                      onChange={handleChange}
                    />
                    {errors.web_url && (
                      <small className="text-danger">{errors.web_url}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Industry<span className="astrik-icon">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="industry_id"
                      onChange={handleChange}
                    >
                      <option disabled selected>Select Industry</option>
                      {dropdownData?.industries?.map((val, index) => (
                        <option
                          key={index}
                          value={val?.id}
                          selected={val?.id === formData?.industry_id}
                        >
                          {val?.name}
                        </option>
                      ))}
                    </select>
                    {errors.industry_id && (
                      <small className="text-danger">{errors.industry_id}</small>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Minimum Salary<span className="astrik-icon">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="salarymin"
                      value={formData.salarymin}
                      onChange={handleChange}
                    />
                    {errors.salarymin && (
                      <small className="text-danger">{errors.salarymin}</small>
                    )}
                  </div>
                </div>
              </div>

              {/* Seniority, Salary Rate */}
              <div className="row g-3 mt-3">
                <div className="col-md-6">
                  <label>
                    Seniority<span className="astrik-icon">*</span>
                  </label>
                  <div className="d-flex flex-wrap gap-3 mt-1">
                    {dropdownData?.seniorities?.map((option, index) => (
                      <label key={index} className="form-check-label">
                        <input
                          className="form-check-input me-1"
                          type="radio"
                          name="seniority"
                          value={option?.seniority}
                          checked={option?.seniority === formData.seniority}
                          onChange={handleChange}
                        />
                        {option?.seniority}
                      </label>
                    ))}
                  </div>
                  {errors.seniority && (
                    <small className="text-danger">{errors.seniority}</small>
                  )}
                </div>
                <div className="col-md-6">
                  <label>
                    Salary Rate<span className="astrik-icon">*</span>
                  </label>
                  <div className="d-flex flex-wrap gap-3 mt-1">
                    {dropdownData?.salaries?.map((option, index) => (
                      <label key={index} className="form-check-label">
                        <input
                          className="form-check-input me-1"
                          type="radio"
                          name="salary_rate"
                          value={option?.salary_rate}
                          checked={option?.salary_rate === formData.salary_rate}
                          onChange={handleChange}
                        />
                        {option?.salary_rate}
                      </label>
                    ))}
                  </div>
                  {errors.salary_rate && (
                    <small className="text-danger">{errors.salary_rate}</small>
                  )}
                </div>
              </div>

              {/* Employment Type */}
              <div className="form-group mt-3">
                <label>
                  Employment Type<span className="astrik-icon">*</span>
                </label>
                <div className="d-flex flex-wrap gap-3 mt-1">
                  {dropdownData?.employee_types?.map((option, index) => (
                    <label key={index} className="form-check-label">
                      <input
                        className="form-check-input me-1"
                        type="checkbox"
                        name="emp_type_id"
                        value={option?.id}
                        checked={employmentType?.includes(option.id)}
                        onChange={handleEmploymentChange}
                      />
                      {option?.type}
                    </label>
                  ))}
                </div>
                {errors.employee_type && (
                  <small className="text-danger">{errors.employee_type}</small>
                )}
              </div>

              {/* Buttons */}
              <div className="d-flex justify-content-end mt-3">
                <div className="rctb-close-div">
                  <button type="button" className="me-1" onClick={onClose}>
                    Cancel
                  </button>
                </div>

                <div className="rctb-first-div">
                  <button type="submit" className="">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>

        </ModalContent >
      </Modal >
    </>
  );
}

export default EditProfileModal;
