import { useState, useEffect } from "react";
import InputComp from "./InputComp";
import "../assets/Styles/WorkExpComp.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SelectComponent from "./SelectComp";
import { getCountryStatesCity } from "../api/services/userService";

const PersonalInfoAddressSection = (props) => {
  const { prop, register, errors } = props;
  const { handleSubmit, control } = useForm();
  const [countries, seCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [zip_country, setZip_Country] = useState({});
  const [zip_state, setZip_State] = useState({});
  const [zip_city, setZip_City] = useState({});

  const EditAddress = (cntry, cntryid, stateid, cityid) => {
    // const country = cntry?.find((country) => country?.country_name == "India");
    // setStates(country ? country?.states : []);
    // setCities([]);
    const country = cntry?.find((country) => country?.country_id == cntryid);
    setStates(country ? country?.states : []);

    if (states) {
      const country = cntry.find((country) => country?.country_id == cntryid);
      const state = country
        ? country?.states?.find((state) => state?.state_id == stateid)
        : null;
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const DefaulStateAddress = (cntry) => {
    const country = cntry?.find((country) => country?.country_name == "India");
    setStates(country ? country?.states : []);
    setCities([]);
    prop.onADDPERSONALINFO({
      ...prop.personalInfo,
      country: country?.country_name,
      country_id: country?.country_id,
    });
  };

  const getCountryCityState = async () => {
    try {
      await getCountryStatesCity().then((response) => {
        seCountries(response?.data?.data?.countries);

        if (prop?.personalInfo?.country_id) {
          EditAddress(
            response?.data?.data?.countries,
            prop?.personalInfo?.country_id,
            prop?.personalInfo?.state_id,
            prop?.personalInfo?.city_id
          );
        } else {
          DefaulStateAddress(response?.data?.data?.countries);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCountryCityState();
  }, [prop?.personalInfo?.country_id]);

  const handleCountryChange = (e) => {
    const cntry = e?.target?.value;
    if (cntry) {
      const country = countries.find(
        (country) => country.country_name == cntry
      );
      setStates(country ? country?.states : []);

      prop.onADDPERSONALINFO({
        ...prop.personalInfo,
        country: cntry,
        country_id: country?.country_id,
      });
    } else {
      const country = countries.find((country) => country.country_id == "101");
      setStates(country ? country?.states : []);
      setCities([]);
    }
  };

  const handleStateChange = (e) => {
    const sState = e.target.value;

    if (sState) {
      const state = states
        ? states?.find((state) => state?.state_name == sState)
        : null;
      prop.onADDPERSONALINFO({
        ...prop.personalInfo,
        state: sState,
        state_id: state?.state_id,
      });
      setCities(state ? state.cities : []);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    if (city) {
      const cityy = city
        ? cities?.find((cityVal) => cityVal?.city_name == city)
        : null;

      prop.onADDPERSONALINFO({
        ...prop.personalInfo,
        city: city,
        city_id: cityy?.city_id,
      });
    }
  };

  // console.log("personalInfo?.country",prop?.personalInfo?.state)
  return (
    <>
      <div className="d-flex gap-5 mt-2">
        {/* <InputComp
          title={"Postal Code"}
          type={"number"}
          asterisk={true}
          name={"postalCode"}
          placeholder={"Postal Code"}
          maxLength={"6"}
          register={register}
          multiline={false}
          value={prop.personalInfo.postalCode}
          setValue={(value) =>
            prop.onADDPERSONALINFO({
              ...prop.personalInfo,
              postalCode: value,
            })
          }
          error={errors.postalCode ? true : false}
          errorMessage={errors.postalCode ? errors.postalCode.message : null}
        /> */}

        <InputComp
          title={"Postal Code"}
          type={"number"}
          asterisk={false}
          name={"postalCode"}
          placeholder={"Postal Code"}
          register={register}
          required={false}
          multiline={false}
          value={prop.personalInfo.postalCode}
          setValue={(value) => {
            if (value.length <= 6) { // Prevent entering more than 6 digits
              prop.onADDPERSONALINFO({
                ...prop.personalInfo,
                postalCode: value,
              });
            }
          }}
          onInput={(e) => {
            if (e.target.value.length > 6) {
              e.target.value = e.target.value.slice(0, 6);
            }
          }}
          error={!!errors.postalCode}
          errorMessage={errors.postalCode ? errors.postalCode.message : null}
        />



        <div className="w-100">
          <label className="radio-btn-label input-title">
            Country<span className="" style={{ color: "red" }}>*</span>
          </label>
          <FormControl fullWidth error={errors.country ? true : false}>
            <Select
              {...register("country", { required: "Please fill this field." })}
              labelId="city-select-label"
              value={prop?.personalInfo?.country || "India"}
              onChange={handleCountryChange}>
              <MenuItem value="" disabled displayEmpty>
                Select Country
              </MenuItem>
              {countries?.map((val, index) => (
                <MenuItem key={index} value={val?.country_name}>
                  {val?.country_name}
                </MenuItem>
              ))}
            </Select>
            {errors?.country && (
              <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>{errors?.country?.message}</p>
            )}
          </FormControl>
        </div>
      </div>

      <div className="d-flex gap-5 mt-3">
        <div className="w-100">
          <label className="radio-btn-label input-title">
            State<span className="" style={{ color: "red" }}></span>
          </label>
          {/* error={errors.state ? true : false} */}
          <FormControl fullWidth >
            <Select
              {...register("state", { required: false })}
              labelId="city-select-label"
              value={prop?.personalInfo?.state || ""}
              onChange={handleStateChange}
              displayEmpty>
              <MenuItem value="" disabled>
                Select State
              </MenuItem>
              {states?.map((val, index) => (
                <MenuItem key={index} value={val?.state_name}>
                  {val?.state_name}
                </MenuItem>
              ))}
            </Select>
            {errors.state && (
              <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>{errors.state.message}</p>
            )}
          </FormControl>
        </div>

        <div className="w-100">
          <label className="radio-btn-label input-title">
            City<span className="" style={{ color: "red" }}></span>
          </label>
          {/* error={errors.city ? true : false} */}
          <FormControl fullWidth >
            <Select
              // {...register("city", { required: "Please fill this field." })}
              {...register("city", { required: false })}
              labelId="city-select-label"
              value={prop?.personalInfo?.city || ""}
              onChange={handleCityChange}
              displayEmpty>
              <MenuItem value="" disabled>
                Select City
              </MenuItem>
              {cities?.map((val, index) => (
                <MenuItem key={index} value={val?.city_name}>
                  {val?.city_name}
                </MenuItem>
              ))}
            </Select>
            {errors.city && (
              <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>{errors.city.message}</p>
            )}
          </FormControl>
        </div>
      </div>
    </>
  );
};
export default PersonalInfoAddressSection;
