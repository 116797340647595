import templateOneImg from "../assets/Images/resume-template-one.png";
import templateTwoImg from "../assets/Images/resume-template-two.png";
import templateThreeImg from "../assets/Images/resume-template-three.png";
import templateFourImg from "../assets/Images/resume-template-four.jpg";
import templateFiveImg from "../assets/Images/resume-template-five.webp";
import templateSixImg from "../assets/Images/ResumeNew1.png";
import templateSevenImg from "../assets/Images/ResumeNew2.png";

import Template1 from "../Templates/Template1";
import Template2 from "../Templates/Template2";
import Template3 from "../Templates/Template3";
import Template4 from "../Templates/Template4";
import Template5 from "../Templates/Template5";
import Template6 from "../Templates/Template6";
import Template7 from "../Templates/Template7.jsx";

export const templates = [
  // {
  //   id: 1,
  //   template_name: "Template One",
  //   template_img: templateOneImg,
  //   template: <Template1 />,
  // },
  // {
  //   id: 2,
  //   template_name: "Template Two",
  //   template_img: templateTwoImg,
  //   template: <Template2 />,
  // },
  {
    id: 6,
    template_name: "Template Six",
    template_img: templateSixImg,
    template: <Template6 />,
  },
  {
    id: 7,
    template_name: "Template Seven",
    template_img: templateSevenImg,
    template: <Template7 />,
  }

];
