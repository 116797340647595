
import React, { useState, useEffect } from "react";
import { Divider, MenuItem, Paper, Select, Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { connect } from "react-redux";
import { ADDEDUCATION } from "../Redux/Actions";
import { useForm, Controller } from "react-hook-form";
import "../assets/Styles/EducationComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import InputComp from "./InputComp";
import InputComponent from "./InputComp";

import SelectComp from "./SelectComp";

const currentYear = new Date().getFullYear();
const Years = Array.from({ length: currentYear - 1950 + 1 }, (_, i) => 1950 + i);


const mapStateToProp = (state) => ({
  educationInfo: state.EducationInfo.educationInfo, // Saved education data from Redux
});

const mapDispatchToProps = (dispatch) => ({
  onAddEducation: (details) => dispatch(ADDEDUCATION(details)),
});

const EducationComp = (props) => {
  const [loading, setLoading] = useState(false);
  const [educationList, setEducationList] = useState([
    { domain: "", university: "", degree: "", start_year: "", education_location: "", end_year: currentYear, description: "" },
  ]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      educationList: props.educationInfo || [
        { domain: "", university: "", degree: "", start_year: "", education_location: "", end_year: currentYear, description: "" },
      ],
    },
    shouldUnregister: false,   // Keeps values when unmounting
    mode: "onBlur",            // Validate onBlur instead of onChange
    reValidateMode: "onChange",
  });
  // Initialize educationList with saved data from Redux (if available)
  useEffect(() => {
    if (props.educationInfo && props.educationInfo.length > 0) {
      setEducationList(props.educationInfo); // Set saved education data
    }
  }, [props.educationInfo]);

  const handleNext = (data) => {
    setLoading(true);
    props.onAddEducation(educationList);

    setTimeout(() => {
      setLoading(false);
      props.setTab(props.tab + 1); // Navigate to the next tab
    }, 1000);
  };

  const handleBack = () => {
    props.setTab(props.tab - 1);
  };

  // Handle adding a new education entry
  const addNewEducation = () => {
    setEducationList([
      ...educationList,
      { domain: "", university: "", degree: "", start_year: "", end_year: "", education_location: "", description: "" },
    ]);
  };

  const deleteEducation = (index) => {
    setEducationList(educationList?.filter((_, i) => i !== index));
  }

  // Handle changes to individual education entry
  const updateEducation = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };


  const updateStart_year = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };


  const updateEnd_year = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };


  const UpdateEducationLocation = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };

  const updateDescription = (index, key, value) => {
    const updatedList = educationList.map((education, i) =>
      i === index ? { ...education, [key]: value } : education
    );
    setEducationList(updatedList);
  };


  return (
    <div className="EducationPaper p-3">
      {/* <Paper className="EducationPaper col-9" elevation={3}> */}

      <h2 className="education-heading">Education Details</h2>
      {/* <Divider sx={{ margin: "10px 0px" }} /> */}
      <form onSubmit={handleSubmit(handleNext)}>
        {educationList.map((education, index) => (
          <>

            <div className="education-form-1 mt-4 pb-4">
              <h3 className="experience-heading pt-3 px-2">Education {index + 1}</h3>
              <div key={index} className="education-form py-3 px-2 mt-4">
                <InputComp
                  title={"University"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.university`}
                  placeholder={"University"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.university}
                  setValue={(value) => updateEducation(index, "university", value)}
                  error={errors?.educationList?.[index]?.university ? true : false}
                  errorMessage={errors?.educationList?.[index]?.university?.message || null}
                />

                <InputComp
                  title={"Degree"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.degree`}
                  placeholder={"Degree"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.degree}
                  setValue={(value) => updateEducation(index, "degree", value)}
                  error={errors?.educationList?.[index]?.degree ? true : false}
                  errorMessage={errors?.educationList?.[index]?.degree?.message || null}
                />


                {/* <SelectComp
                  title={"Start Year"}
                  asterisk={true}
                  errorMessage={
                    errors?.educationList?.[index]?.start_year?.message || null
                  }
                  error={!!errors?.educationList?.[index]?.start_year}
                >
                  <Controller
                    name={`educationList.${index}.start_year`}
                    control={control}
                    rules={{
                      required: "*Please Select Start Year",
                      validate: (value) =>
                        !value ||
                        value <= getValues(`educationList.${index}.end_year`) ||
                        "*Start year must be before or the same as end year",
                    }}
                    defaultValue={education.start_year}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.start_year}
                        onChange={(e) => {
                          updateEducation(index, "start_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors?.educationList?.[index]?.start_year}
                      >
                        <MenuItem value="" disabled>
                          Select a start year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp>

                <SelectComp
                  title={"End Year"}
                  asterisk={true}
                  errorMessage={
                    errors?.educationList?.[index]?.end_year?.message || null
                  }
                  error={!!errors?.educationList?.[index]?.end_year}
                >
                  <Controller
                    name={`educationList.${index}.end_year`} // ✅ Correct naming
                    control={control}
                    rules={{
                      required: "*Please Select End Year",
                      validate: (value) =>
                        !value ||
                        value >= getValues(`educationList.${index}.start_year`) ||
                        "*End year must be after or the same as start year",
                    }}
                    defaultValue={education.end_year} // ✅ Ensure defaultValue is set
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={education.end_year}
                        onChange={(e) => {
                          updateEducation(index, "end_year", e.target.value);
                          field.onChange(e);
                        }}
                        error={!!errors?.educationList?.[index]?.end_year}
                      >
                        <MenuItem value="" disabled>
                          Select an end year
                        </MenuItem>
                        {Years?.map((Year, idx) => (
                          <MenuItem key={idx} value={Year}>
                            {Year}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </SelectComp> */}

                <InputComp
                  title={"Start Date"}
                  asterisk={true}
                  type={"date"}
                  name={`start_year${index}.start_year`}
                  register={register}
                  value={education.start_year || ""}
                  setValue={(value) => updateStart_year(index, "start_year", value)}
                  error={errors?.[`start_year${index}`]?.start_year ? true : false}
                  errorMessage={errors?.[`start_year${index}`]?.start_year?.message || null}
                />


                <InputComp
                  title={"End Date"}
                  asterisk={true}
                  type={"date"}
                  name={`end_year${index}.end_year`}
                  register={register}
                  value={education.end_year || ""}
                  setValue={(value) => updateEnd_year(index, "end_year", value)}
                  error={errors?.[`end_year${index}`]?.end_year ? true : false}
                  errorMessage={errors?.[`end_year${index}`]?.end_year?.message || null}
                />



                <InputComp
                  title={"Education Location"}
                  asterisk={true}
                  type={"text"}
                  name={`educationList.${index}.education_location`}
                  placeholder={"Enter Education Location"}
                  register={register}
                  value={education.education_location || ""}
                  maxLength={'40'}
                  setValue={(value) => UpdateEducationLocation(index, "education_location", value)}

                  error={errors?.educationList?.[index]?.education_location ? true : false}
                  errorMessage={errors?.educationList?.[index]?.education_location?.message || null}
                />


                <InputComp
                  title={"Domain"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.domain`}
                  placeholder={"Domain"}
                  maxLength={"30"}
                  register={register}
                  multiline={false}
                  value={education.domain}
                  setValue={(value) => updateEducation(index, "domain", value)}
                  error={errors?.educationList?.[index]?.domain ? true : false}
                  errorMessage={errors?.educationList?.[index]?.domain?.message || null}
                />

              </div>
              <div className="px-2">
                <InputComponent
                  title={"Description"}
                  type={"text"}
                  asterisk={true}
                  name={`educationList.${index}.description`}
                  placeholder={"Description"}
                  maxLength={1000}
                  register={register}
                  multiline={true}
                  value={education.description}
                  setValue={(value) => updateDescription(index, "description", value)}
                  error={errors?.educationList?.[index]?.description ? true : false}
                  errorMessage={errors?.educationList?.[index]?.description?.message || null}
                />

                {index !== 0 && (
                  <div className="d-flex justify-content-end mt-0">
                    <DeleteOutlineOutlinedIcon
                      sx={{ marginLeft: "0px", marginTop: "35px", color: "#f14747", cursor: 'pointer' }}
                      onClick={() => deleteEducation(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ))}

        {educationList.length === 10 ? null : (
          <div className="add-new-btn-cont">
            <Button
              variant="text"
              className="add-new-btn-cont"
              color="primary"
              onClick={addNewEducation}
              type="button"
              style={{ marginBottom: "20px" }}
            >
              Add New Education
            </Button>
          </div>
        )}

        <BackNextBtnComp
          onNext={handleSubmit(handleNext)}
          onBack={handleBack}
          loading={loading}
          tab={props.tab}
          nextTitle={"Next"}
          backTitle={"Back"}
        />
      </form>
      {/* </Paper> */}
    </div>
  );
};

export default connect(mapStateToProp, mapDispatchToProps)(EducationComp);