import templateOneImg from "../assets/Images/resume-template-one.png";
import templateTwoImg from "../assets/Images/resume-template-two.png";
import templateThreeImg from "../assets/Images/resume-template-three.png";
import templateFourImg from "../assets/Images/NewCv1.png";
import templateFiveImg from "../assets/Images/NewCv2.png";
// import templateSixImg from "../assets/Images/resume-template-six.jpg";

import Template1 from "../Templates/Template1";
import Template2 from "../Templates/Template2";
import Template3 from "../Templates/Template3";
import Template4 from "../Templates/Template4";
import Template5 from "../Templates/Template5";
import Template6 from "../Templates/Template6";

export const Cvtemplates = [
  //   {
  //     id: 1,
  //     template_name: "Template One",
  //     template_img: templateOneImg,
  //     template: <Template1 />,
  //   },
  //   {
  //     id: 2,
  //     template_name: "Template Two",
  //     template_img: templateTwoImg,
  //     template: <Template2 />,
  //   },
  // {
  //   id: 3,
  //   template_name: "Template Three",
  //   template_img: templateThreeImg,
  //   template: <Template3 />
  // },
  {
    id: 4,
    template_name: "Template Four",
    template_img: templateFourImg,
    template: <Template4 />
  },
  {
    id: 5,
    template_name: "Template Five",
    template_img: templateFiveImg,
    template: <Template5 />
  }
];
