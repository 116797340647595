import { Button, Divider, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../assets/Styles/PersonalInfoComp.css";
import BackNextBtnComp from "./BackNextBtnComp";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { ADDPERSONALINFO } from "../Redux/Actions";
import InputComp from "./InputComp";
import PersonalInfoAddressSection from "./PersonalInfoAddressSection";
import PhoneInput from "react-phone-input-2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const mapStateToProps = (state) => ({
    personalInfo: state.personalInfoReducer.personalInfo,
});

const mapDispatchToProps = (dispatch) => ({
    onADDPERSONALINFO: (details) => dispatch(ADDPERSONALINFO(details)),
});

const CoverLetterPersonalInfo = (props, setTab, tab) => {
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm();

    const { personalInfo } = props;

    const handleNext = (data) => {
        setLoading(true);
        props.onADDPERSONALINFO(data);

        setTimeout(() => {
            setLoading(false);
            props.setTab(0);
        }, 1000);
    };

    const handlePhoneChange = (value, country) => {
        const phoneNum = value?.slice(country?.dialCode?.length);
        const d_code = `${country?.format?.charAt(0)}${country?.dialCode}`;

        props.onADDPERSONALINFO({
            ...props.personalInfo,
            country_code: d_code,
            phone: phoneNum,
        });

        setValue("phone", phoneNum); // Set the phone number in the form state

        // Clear error if the number is valid (10 digits or more)
        if (phoneNum.length >= 10) {
            clearErrors("phone");
        } else {
            if (phoneNum.length > 0) {
                setValue("phone", phoneNum); // Keep updating the value
            }
        }
    };

    useEffect(() => {
        if (props?.personalInfo?.phone) {
            setValue("phone", `${props?.personalInfo?.phone}`);
        }
    }, [props?.personalInfo, setValue]);

    const handleChange = (value) => {
        // Strip HTML tags to count only plain text characters
        const plainText = value.replace(/<\/?[^>]+(>|$)/g, "");
        if (plainText.length <= 2000) {
            props.onADDPERSONALINFO({
                ...props.personalInfo,
                objective: value,
            });
        }
    };

    return (
        <div className="personal-info-paper p-3">
            <form onSubmit={handleSubmit(handleNext)}>
                <div className="personal-Info-form-field">
                    {/* Row 1: First Name and Last Name */}
                    <div className="mt-2">
                        <h5>Personal Details</h5>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <InputComp
                                    title={"First Name"}
                                    asterisk={true}
                                    type={"text"}
                                    name={"firstName"}
                                    placeholder={"First Name"}
                                    maxLength={"15"}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.firstName}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            firstName: value,
                                        })
                                    }
                                    error={errors.firstName ? true : false}
                                    errorMessage={errors.firstName ? errors.firstName.message : null}
                                />
                            </div>
                            <div className="col-md-6">
                                <InputComp
                                    title={"Last Name"}
                                    asterisk={true}
                                    type={"text"}
                                    name={"lastName"}
                                    placeholder={"Last Name"}
                                    maxLength={"15"}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.lastName}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            lastName: value,
                                        })
                                    }
                                    error={errors.lastName ? true : false}
                                    errorMessage={errors.lastName ? errors.lastName.message : null}
                                />
                            </div>
                        </div>

                        {/* Row 2: Email and Mobile */}
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <InputComp
                                    title={"Email"}
                                    asterisk={true}
                                    type={"email"}
                                    name={"email"}
                                    placeholder={"Email"}
                                    maxLength={35}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.email}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            email: value,
                                        })
                                    }
                                    error={errors.email ? true : false}
                                    errorMessage={errors.email ? errors.email.message : null}
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="form-outline w-100">
                                    <label className="radio-btn-label input-title">
                                        Mobile
                                        <span className="" style={{ color: "red" }}>
                                            *
                                        </span>
                                    </label>

                                    <PhoneInput
                                        {...register("phone", {
                                            required: "Please fill this field.", // Required validation
                                            validate: {
                                                validPhone: (value) =>
                                                    value.length >= 10 ||
                                                    "Phone number must be at least 10 digits.",
                                            },
                                        })}
                                        value={`${props?.personalInfo?.country_code}${props?.personalInfo?.phone}`}
                                        name="phone"
                                        onChange={handlePhoneChange}
                                        className="w-100"
                                        country={"in"}
                                        inputClass="resumePhoneInput"
                                        placeholder="phone"
                                    />
                                    {errors.phone && (
                                        <p style={{ color: "#d32f2f", fontSize: 13, marginLeft: 14 }}>
                                            {errors.phone.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <InputComp
                                    title={"Job Title"}
                                    asterisk={true}
                                    type={"text"}
                                    name={"cv_job_title"}
                                    placeholder={"Job Title"}
                                    maxLength={"50"}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.cv_job_title}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            cv_job_title: value,
                                        })
                                    }
                                    error={errors.cv_job_title ? true : false}
                                    errorMessage={
                                        errors.cv_job_title ? errors.cv_job_title.message : null
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <h5>Employer Details</h5>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <InputComp
                                    title={"Company Name"}
                                    asterisk={true}
                                    type={"text"}
                                    name={"company_name"}
                                    placeholder={"Company Name"}
                                    maxLength={"50"}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.company_name}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            company_name: value,
                                        })
                                    }
                                    error={errors.company_name ? true : false}
                                    errorMessage={
                                        errors.company_name ? errors.company_name.message : null
                                    }
                                />
                            </div>


                            <div className="col-md-6">
                                <InputComp
                                    title={"Manager Name"}
                                    asterisk={true}
                                    type={"text"}
                                    name={"manager_name"}
                                    placeholder={"Manager Name"}
                                    maxLength={"50"}
                                    register={register}
                                    multiline={false}
                                    value={props.personalInfo.manager_name}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            manager_name: value,
                                        })
                                    }
                                    error={errors.manager_name ? true : false}
                                    errorMessage={
                                        errors.manager_name ? errors.manager_name.message : null
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-4">
                        <h5>Address Details</h5>
                        <div className="row mt-2">
                            <PersonalInfoAddressSection
                                prop={props}
                                register={register}
                                errors={errors}
                            />
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12">
                                {/* Address Field */}
                                <InputComp
                                    title={"Address"}
                                    type={"text"}
                                    asterisk={true}
                                    name={"address"}
                                    placeholder={"Address"}
                                    maxLength={"100"}
                                    register={register}
                                    multiline={true}
                                    value={props.personalInfo.address}
                                    setValue={(value) =>
                                        props.onADDPERSONALINFO({
                                            ...props.personalInfo,
                                            address: value,
                                        })
                                    }
                                    error={errors.address ? true : false}
                                    errorMessage={errors.address ? errors.address.message : null}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="mt-4">
                        <h5>Letter Details</h5>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <label>
                                    Letter Details <span style={{ color: "red" }}>*</span>
                                </label>
                                <div style={{ textAlign: "right", fontSize: "smaller" }}>
                                    {props.personalInfo.objective.replace(/<\/?[^>]+(>|$)/g, "").length}
                                    /2000
                                </div>

                                <ReactQuill
                                    value={props.personalInfo.objective}
                                    onChange={handleChange}
                                    style={{ height: "180px", marginBottom: "50px" }}
                                    placeholder="Write your Letter Details..."
                                />

                                {props.personalInfo.objective.replace(/<\/?[^>]+(>|$)/g, "").length ===
                                    0 && (
                                        <div
                                            style={{ color: "#d32f2f", marginTop: "5px", fontSize: "smaller" }}
                                        >
                                            Letter Details is required.
                                        </div>
                                    )}

                                {props.personalInfo.objective.replace(/<\/?[^>]+(>|$)/g, "").length >=
                                    2000 && (
                                        <div
                                            style={{ color: "#d32f2f", marginTop: "5px", fontSize: "smaller" }}
                                        >
                                            You cannot add more than 2000 characters.
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>

                </div>

                <Divider className="personal-details-divider" />
                <BackNextBtnComp
                    loading={loading}
                    tab={props.tab}
                    nextTitle={"Save"}
                    backTitle={"Back"}
                />
            </form>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetterPersonalInfo);