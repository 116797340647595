import React from 'react';
import { data } from "../Data/data";
import "../assets/Styles/Template5.css";


export default function Template5(props) {

    const personalInfo = props.personalInfo ? props.personalInfo : data.personal_info;

    const skills = props.skills ? props.skills : data.key_skills;

    const educationInfo = props.educationInfo ? props.educationInfo : data.education_details;

    const experiences = props.experiences ? props.experiences : data.work_experience;



    return (
        <>
            <div className="resume-container-5">
                {/* {personalInfo?.firstName && 'Mr'}  */}
                <div className="resume-header-5 text-center pt-2 mb-0">{`${personalInfo?.firstName} ${personalInfo?.lastName}`}</div>
                <p className="text-center">{personalInfo?.cv_job_title}</p>

                {personalInfo?.firstName &&
                    <div className="resume-5-line"></div>
                }


                <div className="d-flex justify-content-between">
                    <div className="resume-contact-To-4 mt-4">
                        {personalInfo?.manager_name && "To"}
                        <p className="m-0">{personalInfo?.manager_name}</p>
                        <p className="m-0">{personalInfo?.company_name}</p>


                        {/* <p>{personalInfo?.firstName} {personalInfo?.lastName}</p>
                        <p>{personalInfo?.cv_job_title}</p> */}

                    </div>

                    <div className="resume-contact-info-5">
                        <p>{personalInfo?.country_code} {personalInfo?.phone}</p>
                        <p>{personalInfo?.email}</p>
                        <p>{personalInfo?.address}</p>
                        <p>{personalInfo?.city} {personalInfo?.state}  {personalInfo?.postalCode}</p>
                        {personalInfo?.postalCode &&
                            <p>{personalInfo?.country}</p>}
                    </div>
                </div>

                {personalInfo?.postalCode &&
                    <div className="resume-5-line"></div>
                }

                <div className="resume-content-5 mt-4">

                    <div dangerouslySetInnerHTML={{ __html: personalInfo?.objective }}></div>

                </div>
            </div>
        </>

    )

}