import React from 'react';
import { data } from "../Data/data";
import "../assets/Styles/Template6.css";
import { useSelector } from 'react-redux';

export default function Template6(props) {

    const personalInfo = props.personalInfo ? props.personalInfo : data.personal_info;

    const skills = props.skills ? props.skills : data.key_skills;

    const hobbies = props.hobbies ? props.hobbies : data.key_hobbies;

    const educationInfo = props.educationInfo ? props.educationInfo : data.education_details;

    const experiences = props.experiences ? props.experiences : data.work_experience;


    const formatDate = (dateString) => {
        if (!dateString) return "Invalid Date"; // Check for null, undefined, or empty string

        const date = new Date(dateString);

        if (isNaN(date.getTime())) return "Invalid Date"; // Check for invalid dates

        const day = String(date.getDate()).padStart(2, '0');
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };



    const formatDateToMMYY = (dateString) => {
        if (!dateString || typeof dateString !== "string") return "Invalid Date";

        const [yy, m, d] = dateString.split('-') || [];  // Avoid undefined destructuring
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const monthIndex = parseInt(m, 10) - 1; // Convert month to 0-based index

        if (isNaN(monthIndex) || monthIndex < 0 || monthIndex > 11) return "Invalid Date";

        return `${months[monthIndex]} ${yy}`;
    };

    // const filteredSkills = skills?.filter(skill => skill?.trim() !== "");
    // const filteredHobbies = hobbies?.filter(skill => skill?.trim() !== "");

    const filteredSkills = skills?.filter(
        skill => typeof skill === 'string' && skill.trim() !== ""
    );

    const filteredHobbies = hobbies?.filter(
        hobbie => typeof hobbie === 'string' && hobbie.trim() !== ""
    );


    const hasWorkExperience = experiences.length > 0 && experiences.some(item =>
        Object.entries(item).some(([key, value]) => key !== 'id' && value && value !== '')
    )


    return (
        <>
            <div className="resume-6-container">
                {/* Header */}
                <div className="resume-6-header">
                    {/* {personalInfo?.firstName && 'Mr'} */}
                    <h1 className="resume-6-name">{personalInfo?.firstName} <span className="resume-6-name-red">{personalInfo?.lastName}</span></h1>
                    <p className="resume-6-subtext">
                        {personalInfo?.dob && (<><span> Date of Birth:</span> {formatDate(personalInfo?.dob)}</>)} {(personalInfo?.dob && personalInfo?.nationality) && "|"} {personalInfo?.nationality && (<><span>Nationality:</span> {personalInfo?.nationality} </>)}
                    </p>

                    <p className="resume-6-subtext">
                        {personalInfo?.postalCode && (
                            <>
                                <span>Address:</span> {personalInfo?.address} {personalInfo?.city} {personalInfo?.state} {personalInfo?.country} {personalInfo?.postalCode}</>
                        )} {(personalInfo?.postalCode && personalInfo?.phone) && "|"}    {personalInfo?.phone && (<><span>Phone number:</span> {personalInfo?.country_code} {personalInfo?.phone} </>)}

                    </p>

                    <p className="resume-6-subtext">
                        {personalInfo?.email && (
                            <>
                                <span>Email address:</span> {personalInfo?.email} </>)} {(personalInfo?.email && personalInfo?.website) && "|"} {personalInfo?.website && (
                                    <> <span>Web: </span>
                                        {personalInfo?.website} </>)}
                    </p>
                </div>

                {/* Profile */}
                {personalInfo?.objective &&
                    <div className="resume-6-section">
                        <div className="d-flex align-item-center">
                            <h2 className="resume-6-section-title">Profile</h2>
                            <hr className="resume-6-line" />
                        </div>
                        <p className="resume-6-profile" dangerouslySetInnerHTML={{ __html: personalInfo?.objective }}>

                        </p>
                    </div>
                }

                {/* Work Experience */}
                {hasWorkExperience &&
                    <div className="resume-6-section">
                        <div className="d-flex align-item-center">
                            <h2 className="resume-6-section-title">Work Experience</h2>
                            <hr className="resume-6-line" />
                        </div>

                        {experiences?.map((value) => (
                            <div className="resume-6-job">
                                <h3 className="resume-6-job-title">
                                    {value?.org_name}
                                </h3>
                                <p className="resume-6-date-location"> {value?.job_title}</p>

                                <p className="resume-6-date-location">{formatDateToMMYY(value?.start_year)} - {formatDateToMMYY(value?.end_year)} | {value?.job_location}</p>

                                <p className="resume-6-job-desc">{value?.description}</p>
                            </div>
                        ))}
                    </div>
                }

                {/* Education */}
                {Object.values(educationInfo).some(value => value && value !== '') &&
                    <div className="resume-6-section">
                        <div className="d-flex align-item-center">
                            <h2 className="resume-6-section-title">Education</h2>
                            <hr className="resume-6-line" />
                        </div>

                        {Array.isArray(educationInfo) && educationInfo?.map((value) => (
                            <div className="resume-6-job" key={value?.id || value?.degree}>
                                <h3 className="resume-6-education-title">{value?.degree}</h3>
                                <p className="resume-6-company p-0 m-0">{value?.university}</p>
                                <p className="resume-6-date-location">{formatDateToMMYY(value?.start_year)} - {formatDateToMMYY(value?.end_year)} | {value?.education_location}</p>
                                <p className="resume-6-job-desc">{value.description}</p>
                            </div>
                        ))}
                    </div>
                }

                {/* Skills */}
                {filteredSkills?.length > 0 &&
                    <div className="resume-6-section">
                        <div className="d-flex align-item-center">
                            <h2 className="resume-6-section-title">Skills</h2>
                            <hr className="resume-6-line" />
                        </div>

                        <ul className="resume-6-job-desc-skill">
                            {skills?.map((skill) => (
                                <>
                                    <li>{skill}</li>
                                </>
                            ))}
                        </ul>
                    </div>
                }

                {/* Hobbies */}
                {filteredHobbies.length > 0 &&
                    <div className="resume-6-section">
                        <div className="d-flex align-item-center">
                            <h2 className="resume-6-section-title">Hobbies</h2>
                            <hr className="resume-6-line" />
                        </div>
                        <ul className="resume-6-job-desc-hobbies">
                            {hobbies?.map((hobby) => (
                                <>
                                    <li>{hobby}</li>
                                </>
                            ))}
                        </ul>
                    </div>
                }
            </div >
        </>

    )

}