import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/Styles/SwiperTemplate.css";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import imageResume from "../../assets/Images/temp-1.jpg";
import { useNavigate } from "react-router";
import { Alltemplates } from "../../Data/AllTemplates";

const SwiperTemplate = () => {
  const navigate = useNavigate();

  const [currentIndex, setCurrentIndex] = useState(0); // Track active slide index

  // const slides = [
  //   "https://swiperjs.com/demos/images/nature-1.jpg",
  //   "https://swiperjs.com/demos/images/nature-2.jpg",
  //   "https://swiperjs.com/demos/images/nature-3.jpg",
  //   "https://swiperjs.com/demos/images/nature-4.jpg",
  //   "https://swiperjs.com/demos/images/nature-5.jpg",
  //   "https://swiperjs.com/demos/images/nature-6.jpg",
  //   "https://swiperjs.com/demos/images/nature-7.jpg",
  //   "https://swiperjs.com/demos/images/nature-8.jpg",
  // ];

  return (
    <div className="app-swiper-template">
      <h2>Templates</h2>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 3,
          slideShadows: true,
        }}
        loop={true}
        onSlideChange={(swiper) => setCurrentIndex(swiper?.realIndex)} // Update active slide index
        breakpoints={{
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },

          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper-swiper-template"
      >
        {Alltemplates?.map((src, index) => (
          <>
            <SwiperSlide
              key={index}
              className={`swiper-slide-custom ${index === currentIndex ? "active-slide" : ""
                }`}
            >
              <img src={src?.template_img} alt={`Slide ${index + 1}`} />
              {index === currentIndex && (
                <button
                  className="custom-active-button"
                  onClick={() => navigate("/templatecard")}
                >
                  Use this template
                </button>
              )}
            </SwiperSlide>

            <SwiperSlide
              key={index}
              className={`swiper-slide-custom ${index === currentIndex ? "active-slide" : ""
                }`}
            >
              <img src={src?.template_img} alt={`Slide ${index + 1}`} />
              {index === currentIndex && (
                <button
                  className="custom-active-button"
                  onClick={() => navigate("/templatecard")}
                >
                  Use this template
                </button>
              )}
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperTemplate;
